// export const API_BASE_URL = "http://192.168.29.195:8070";
// export const API_BASE_URL = "http://localhost:8070";
export const API_BASE_URL = "https://api.thanawi.io";
// export const API_BASE_URL = "https://dev.api.thanawi.io";
// export const API_BASE_URL = "http://103.204.189.64:5003";

export const MEDIA_URL = "https://dqamw6n6ttqcg.cloudfront.net";
// export const MEDIA_URL = " https://dwjjrp855v9wk.cloudfront.net";

export const ApiEndPoints = {
  AUTH: {
    login: `${API_BASE_URL}/api/v2/auth/login`,
    register_new_user: `${API_BASE_URL}/api/v2/auth/register`,
    verify_otp: `${API_BASE_URL}/api/v2/auth/otp/verify`,
    resend_otp: `${API_BASE_URL}/api/v2/auth/otp/resend`,
    forgot_password: `${API_BASE_URL}/api/v1/admin/auth/forgot-password`,
  },
  USER: {
    me: `${API_BASE_URL}/api/v1/auth/me`,
  },

  LOGIN_USER: {
    forgot_password: `${API_BASE_URL}/api/v1/auth/forgot-password`,
    forgot_password_verify_otp: `${API_BASE_URL}/api/v1/auth/otp-verify`,
    reset_password: `${API_BASE_URL}/api/v1/auth/reset-password`,
  },

  REGISTER_USER: {
    register_sent_otp: `${API_BASE_URL}/api/v1/auth/send-otp`,
    register_verify_otp: `${API_BASE_URL}/api/v1/auth/otp-verify`,
    register_user: `${API_BASE_URL}/api/v1/auth/auth-register`,
  },
  CREATE_SELLER: {
    all_company_list: `${API_BASE_URL}/api/v1/company`,
    type_of_ownership: `${API_BASE_URL}/api/v1/company/ownership`,
    company_type: `${API_BASE_URL}/api/v1/company/types`,
    select_stage: `${API_BASE_URL}/api/v1/company/startup`,
    purpose_of_sale: `${API_BASE_URL}/api/v1/company/sale-types`,
    type_of_company_categories: `${API_BASE_URL}/api/v1/company/catagories`,
    get_seller_by_token: `${API_BASE_URL}/api/v1/seller/profile`,
    get_last_stage_questions: `${API_BASE_URL}/api/v1/questions/`,
    get_cr_details: (cr_id) =>
      `${API_BASE_URL}/api/v1/contracts/commercial-info/${cr_id}`,
  },
  CONTRACT: {
    create_contract: `${API_BASE_URL}/api/v1/contracts`,
    get_contract: `${API_BASE_URL}/api/v1/contracts`,
    get_contract_details_by_id: (contractId) =>
      `${API_BASE_URL}/api/v1/contracts/${contractId}`,
    get_approved_contract_list: `${API_BASE_URL}/api/v1/contracts/company-names`,
    verify_contract_name: (companyName) =>
      `${API_BASE_URL}/api/v1/contracts/verify/${companyName}`,
  },

  HOLDING: {
    get_holding_details_by_contract_id: (contractId) =>
      `${API_BASE_URL}/api/v1/contracts/${contractId}/stock-holding`,
    update_holding_details: (stockHoldingId) =>
      `${API_BASE_URL}/api/v1/stock-holdings/${stockHoldingId}`,
    get_listed_stock_holding: `${API_BASE_URL}/api/v1/stock-holdings/listed`,
    get_seller_from_contract_name: `${API_BASE_URL}/api/v1/stock-holdings/stockHoldListedByName`,
    listed_holdings_list: `${API_BASE_URL}/api/v1/stock-holdings/listed/my`,
    bought_holdings_list: `${API_BASE_URL}/api/v1/stock-holdings/bought`,
    get_holding_details_by_Id: (stockHoldingId) =>
      `${API_BASE_URL}/api/v1/stock-holdings/${stockHoldingId}`,
    get_holding_update_request_status: (stockHoldingId) =>
      `${API_BASE_URL}/api/v1/stock-holdings/${stockHoldingId}/request/status/price-change`,
    get_who_buy_this_stocks: (stockHoldingId) =>
      `${API_BASE_URL}/api/v1/stock-holdings/${stockHoldingId}/buyers`,
  },

  DASHBOARD: {
    create_buy_request: `${API_BASE_URL}/api/v1/buy-requests`,
    get_seller_list: `${API_BASE_URL}/api/v1/seller/approved`,
    get_holdings_list: `${API_BASE_URL}/api/v1/stock-holdings/listed`,
    get_holding_details_by_id: (holdingID) =>
      `${API_BASE_URL}/api/v1/stock-holdings/${holdingID}`,
  },
  SELLER: {
    get_buy_request: `${API_BASE_URL}/api/v1/buy-requests`,
    get_buy_request_list_as_buyer: `${API_BASE_URL}/api/v1/buy-requests/as-buyer`,
    get_buy_request_list_as_seller: `${API_BASE_URL}/api/v1/buy-requests/as-seller`,
    approved_buy_request: (buyRequestId) =>
      `${API_BASE_URL}/api/v1/buy-requests/${buyRequestId}/status/accept`,
    reject_buy_request: (buyRequestId) =>
      `${API_BASE_URL}/api/v1/buy-requests/${buyRequestId}/status/reject`,

    upload_document: (buyRequestId) =>
      `${API_BASE_URL}/api/v1/buy-requests/${buyRequestId}/status/upload-payment-proof`,

    comments: (buyRequestId, channel) =>
      `${API_BASE_URL}/api/v1/buy-requests/${buyRequestId}/comment/${channel}`,
    sent_otp_accept_buy_requests: (buyRequestId) =>
      `${API_BASE_URL}/api/v1/buy-requests/${buyRequestId}/status/accept/send/otp`,
    sent_otp_upload_payment_proof: (buyRequestId) =>
      `${API_BASE_URL}/api/v1/buy-requests/${buyRequestId}/status/upload-payment-proof/send/otp`,
  },
  MISC: {
    allData: `${API_BASE_URL}/api/v1/misc`,
  },
  USER_PROFILE: {
    get_user_profile: `${API_BASE_URL}/api/v1/users/profile`,
    edit_user_profile: `${API_BASE_URL}/api/v1/users/profile`,
  },
  NOTIFICATIONS: {
    get_notifications: `${API_BASE_URL}/api/v1/notifications`,
    sent_fcm_token: `${API_BASE_URL}/api/v1/notifications/token`,
    get_unread_notification_count: `${API_BASE_URL}/api/v1/notifications/unread/count`,
    read_notification: `${API_BASE_URL}/api/v1/notifications/read`,
  },
  TRANSACTION: {
    get_transaction_history: `${API_BASE_URL}/api/v1/transactions`,
  },
  COMMENTS: {
    get_comments: (buyRequestId, channel) =>
      `${API_BASE_URL}/api/v1/buy-requests/${buyRequestId}/comment/${channel}`,
  },
  WALLET_BALANCE: {
    balance_details: `${API_BASE_URL}/api/v1/users/holdings/balance`,
  },
  CREATE_USER_DETAILS_REQUEST_THROUGH_NATIONAL_ID: {
    create_nafath_id_request: `${API_BASE_URL}/api/v1/nafath-request/national-id/verify`,
    check_nafath_id_request_status: `${API_BASE_URL}/api/v1/nafath-request/national-id/verify/status`,
  },
  DOWNLOAD_CERTIFICATE: {
    download_certificate: (holding_contract_id) =>
      `${MEDIA_URL}/public/holding-contracts/${holding_contract_id}.pdf`,
  },
  GET_IN_TOUCH: {
    get_in_touch: `${API_BASE_URL}/api/v1/get-in-touch`,
  },
  INVEST_BY_COMPANY: {
    submit: `${API_BASE_URL}/api/v1/business/add-business`,
    get_submitted_list: `${API_BASE_URL}/api/v1/business/get-business`,
    get_business_by_id: (id) =>
      `${API_BASE_URL}/api/v1/business/get-business/${id}`,
    approved_business_list: `${API_BASE_URL}/api/v1/business/admin-approve-business`,
  },
  SAFE_NOTE: {
    get_approved_contract_list: `${API_BASE_URL}/api/v1/business/admin-approve-safe-note`,
    get_wishlist: `${API_BASE_URL}/api/v1/wishlist`,
    get_list_of_used_safe_note_company: (id) =>
      `${API_BASE_URL}/api/v1/business/business-list/${id}`,
    add_wishlist: `${API_BASE_URL}/api/v1/wishlist/add-wishlist`,
    remove_wishlist: `${API_BASE_URL}/api/v1/wishlist/remove-wishlist`,
    update_status_of_safe_note_company: `${API_BASE_URL}/api/v1/business/status-update`,
    renew_expired_companies: (businessId) =>
      `${API_BASE_URL}/api/v1/business/status/${businessId}`,
    remove_companies_from_my_profile: `${API_BASE_URL}/api/v1/business/remove`,
  },
  PING: {
    get: `${API_BASE_URL}/api/v1/ping/get`,
    delete: `${API_BASE_URL}/api/v1/ping/delete`,
    postJSON: `${API_BASE_URL}/api/v1/ping/post-with-json-body`,
    postFormData: `${API_BASE_URL}/api/v1/ping/post-with-form-data`,
  },
  PERFORMANCE_INSIDE: {
    get_data: `${API_BASE_URL}/api/v1/states`,
  },
};
